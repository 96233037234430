

















































































import { ApiMethod } from "@/components/Modules/modules/ApiNotifyHandlerModule/components/apidata";

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue';
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Prop, Mixins } from 'vue-property-decorator';

@Component({
  name: 'MethodParams',
  components: {
    Accordion,
    SelectInput
  }
})
export default class MethodParams extends Mixins(ModelSetter) {
  @Prop() params!: ApiMethod['params']

  @Prop({ type: String, default: null, required: false }) header!: string | null

  @Prop({ type: Boolean, default: false }) isRoot!: boolean

  private selectedItem = ''

  get listItems() {
    if (this.params) {
      if (this.params.type === "simple") {
        return this.params.items
      } else {
        return this.params.items.field?.[this.type]?.items ?? []
      }
    }

    return []
  }

  get type() {
    if (this.params?.type === "variation" && !this.selectedItem) {
      return this?.params?.items?.options?.[0]?.value ?? ''
    }

    return this.selectedItem
  }

  set type(value: string) {
    this.selectedItem = value
  }
}
