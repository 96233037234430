var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.params)?_c('div',{staticClass:"method-params"},[(_vm.params.type === 'variation')?[_c('select-input',{staticClass:"w-full md:w-1/2 mt-0 pt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'type',
          'prefix': _vm.params.items.title,
          'options': _vm.params.items.options,
          'clearable': false,
          'supportButtons': false
        },
      }}})]:_vm._e(),_c('a-list',{staticClass:"method-params__list",attrs:{"item-layout":"horizontal","data-source":_vm.listItems},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{staticClass:"py-2"},[_c('a-list-item-meta',[_c('div',{attrs:{"slot":"description"},slot:"description"},[_c('div',{domProps:{"innerHTML":_vm._s(item.description)}}),(item.params)?_c('accordion',{staticClass:"nested-params mt-2",attrs:{"close-title":_vm.$t('api_docs_nested_params'),"open-title":_vm.$t('api_docs_nested_params')}},[_c('method-params',{attrs:{"params":item.params}})],1):_vm._e()],1),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(item.key)+" "),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',{staticClass:"font-normal text-sm"},[_vm._v(" "+_vm._s(item.type)+", "),(typeof item.required === 'undefined' || typeof item.required === 'boolean' && item.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" required ")]):_c('span',[_vm._v(" optional ")])])],1)])],1)}}],null,false,1865100829)},[(_vm.isRoot)?_c('div',{staticClass:"flex flex-col sm:flex-row sm:justify-between sm:items-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.header)+" "),_c('span',{staticClass:"text-primary"},[_vm._v(" application/json ")])]):_vm._e()])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }