import { SelectOption } from "piramis-base-components/src/logic/types";
import i18n from "@/i18n/i18n";

export const apidata: ApiData = [
  {
    method: 'getinfo',
    type: "POST",
    description: i18n.t('api_docs_method_getinfo_description').toString()
  },
  {
    method: 'getuserstatus',
    type: "POST",
    description: i18n.t('api_docs_method_getuserstatus_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        }
      ]
    }
  },
  {
    method: 'getuserinfo',
    type: "POST",
    description: i18n.t('api_docs_method_getuserinfo_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        }
      ]
    }
  },
  {
    method: 'changereputation',
    type: "POST",
    description: i18n.t('api_docs_method_changereputation_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "value",
          type: 'number',
          description: i18n.t('api_docs_method_changereputation_param_value').toString()
        },
        {
          key: "lower_limit",
          type: 'number',
          description: i18n.t('api_docs_param_lower_limit').toString()
        },
        {
          key: "upper_limit",
          type: 'number',
          description: i18n.t('api_docs_param_upper_limit').toString()
        },
        {
          key: "reason",
          type: 'string',
          description: i18n.t('api_docs_param_reason').toString(),
          required: false
        }
      ]
    }
  },
  {
    method: 'changepoints',
    type: "POST",
    description: i18n.t('api_docs_method_changepoints_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "user_id",
          type: 'number',
          description: i18n.t("api_docs_param_user_id").toString()
        },
        {
          key: "value",
          type: 'number',
          description: i18n.t('api_docs_method_changepoints_param_value').toString()
        },
        {
          key: "lower_limit",
          type: 'number',
          description: i18n.t('api_docs_param_lower_limit').toString()
        },
        {
          key: "upper_limit",
          type: 'number',
          description: i18n.t('api_docs_param_upper_limit').toString()
        },
        {
          key: "reason",
          type: 'string',
          description: i18n.t('api_docs_param_reason').toString(),
          required: false
        }
      ]
    }
  },
  // {
  //   method: 'transfer_license',
  //   type: "POST",
  //   description: i18n.t('api_docs_method_transfer_license_description').toString(),
  //   params: {
  //     type: "simple",
  //     items: [
  //       {
  //         key: "creator",
  //         type: 'number',
  //         description: i18n.t('api_docs_method_transfer_license_param_creator').toString()
  //       },
  //       {
  //         key: "target",
  //         type: 'number ',
  //         description: i18n.t('api_docs_method_transfer_license_param_target').toString()
  //       },
  //     ]
  //   }
  // },
  {
    method: 'send_chat_message',
    type: "POST",
    description: i18n.t('api_docs_method_send_chat_message_description').toString(),
    params: {
      type: "simple",
      items: [
        {
          key: "messages",
          type: 'array',
          description: i18n.t('send_chat_message_param_messages').toString(),
          params: {
            type: "simple",
            items: [
              {
                key: "text",
                type: "string",
                description: i18n.t('send_chat_message_param_messages_text').toString(),
              },
              {
                key: "disable_link_preview",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_disable_link_preview').toString(),
              },
              {
                key: "pin",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_pin').toString(),
              },
              {
                key: "disable_notify",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_disable_notify').toString(),
              },
              {
                key: "protect_content",
                type: "boolean",
                required: false,
                description: i18n.t('send_chat_message_param_messages_protect_content').toString(),
              },
              {
                key: "remove_after",
                type: "number",
                required: false,
                description: i18n.t('send_chat_message_param_messages_remove_after').toString(),
              },
              {
                key: "buttons",
                type: "object[ ][ ]",
                required: false,
                description: i18n.t('send_chat_message_param_messages_buttons').toString(),
                params: {
                  type: "variation",
                  items: {
                    title: "api_docs_buttons_",
                    options: [
                      {
                        label: 'Link',
                        value: 'Link',
                      },
                      {
                        label: 'Reaction',
                        value: 'Reaction',
                      },
                    ],
                    field: {
                      "Link": {
                        type: "simple",
                        items: [
                          {
                            key: "type",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_type_link').toString(),
                          },
                          {
                            key: "text",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_text').toString(),
                          },
                          {
                            key: "link",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_link_link').toString(),
                          }
                        ]
                      },
                      "Reaction": {
                        type: "simple",
                        items: [
                          {
                            key: "type",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_type_reaction').toString(),
                          },
                          {
                            key: "text",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_text').toString(),
                          },
                          {
                            key: "onlyUnique",
                            type: "boolean",
                            description: i18n.t('send_chat_message_param_messages_buttons_reaction_only_unique').toString(),
                          },
                          {
                            key: "message",
                            type: "string",
                            description: i18n.t('send_chat_message_param_messages_buttons_reaction_message').toString(),
                          }
                        ]
                      }
                    }
                  }
                }
              },
            ]
          }
        },
        {
          key: "actor",
          type: 'number',
          description: i18n.t('send_chat_message_param_actor').toString(),
        },
      ]
    }
  }
]

export type ApiData = Array<ApiMethod>

export type ApiMethod = {
  method: string
  type: "POST" | "GET"
  description: string
  params?: SimpleParams | VariationParams
}

type SimpleParams = {
  type: "simple"
  items: Array<RequestParam>
}

type VariationParams = {
  type: "variation"
  items: VariationField
}

type RequestParam = {
  key: string
  type: string
  description?: string
  required?: boolean
  params?: SimpleParams | VariationParams
}

type VariationField = {
  title: string
  options: Array<SelectOption>
  field: Record<string, SimpleParams | VariationParams>
}
